import { useMutation } from '@apollo/client';
import { Button, Dropdown, Spin, Menu } from 'antd';
import { ColorMain } from 'config/main';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { RemoveModal } from 'components/removeModal';

import { Icon3Dots } from 'assets/icons/icon3Dots';
import { IconDelete } from 'assets/icons/iconDelete';
import { IconPlus } from 'assets/icons/iconPlus';
import { IconSettings } from 'assets/icons/iconSettings';

import { DeleteChampionshipGroup } from 'queries/sportsChampionshipGroups';
import { ChampionshipGroup, Mutation, MutationDeleteChampionshipGroupArgs } from 'queries/types';

const Container = styled.div`
  border: 1px solid #eef2f8;
  background: white;
  width: 25%;
`;

const StyledH4 = styled.h4`
  padding: 20px 23px;
  margin: 0;
  border-bottom: 1px solid #eef2f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledUl = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  max-height: calc(100vh - 320px);
  overflow-y: auto;
`;

const StyledLi = styled.li`
  padding: 23px;
  transition: background-color 0.25s ease-in-out;
  flex-grow: 1;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eef2f8;

  &:hover,
  &:focus,
  &.active {
    cursor: pointer;
    background-color: #f7f7f8;

    h6 {
      color: #9845fd;
    }
  }
`;

const Name = styled.h6`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 3px;
  transition: color 0.25s ease-in-out;
`;

interface GroupsProps {
  data?: ChampionshipGroup[] | null | undefined;
  loading?: boolean;
}

export const Groups: React.VFC<GroupsProps> = ({ data, loading }: GroupsProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [groupIdToRemove, setGroupIdToRemove] = useState<string>('');

  const {
    uuid,
    competition,
    stage,
    group: groupUrl,
  } = useParams<{
    uuid: string;
    competition: string;
    stage: string;
    group: string;
  }>();

  const [deleteGroup, { loading: isRemoving, data: isRemoved }] = useMutation<
    Mutation,
    MutationDeleteChampionshipGroupArgs
  >(DeleteChampionshipGroup);
  useEffect(() => {
    if (isRemoved) {
      history.push(`${ROUTES.SportsChampionships}/${uuid}/${competition}/${stage}`);
      window.location.reload();
    }
  }, [isRemoved]);

  return (
    <Container>
      <StyledH4>
        {t('sportsChampionship.sections.groups')}
        <Button
          onClick={() =>
            history.push(
              `${ROUTES.SportsChampionships}/${uuid}/${competition}/${
                stage ? `${stage}/` : ''
              }group/create`
            )
          }
          icon={<IconPlus width="15" height="15" fill={ColorMain} />}
          shape="circle"
          type="text"
          disabled={!competition}
        />
      </StyledH4>
      {!loading ? (
        <>
          <StyledUl>
            {data &&
              data.map((group: ChampionshipGroup) => (
                <Wrapper key={group.uuid} className={groupUrl === group.uuid ? 'active' : ''}>
                  <StyledLi
                    onClick={() =>
                      history.push(
                        `${ROUTES.SportsChampionships}/${group.championshipStage.championshipCompetition.championship.uuid}/${group.championshipStage.championshipCompetition.uuid}/${group.championshipStage.uuid}/${group.uuid}/matches`
                      )
                    }
                  >
                    {group.uuid && <Name>{group.name.pt}</Name>}
                    {/* <Sport>{stage.name.pt}</Sport> */}
                  </StyledLi>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key={`competitions-group-edit-${group.uuid}`}>
                          <Link
                            to={`${ROUTES.SportsChampionships}/${uuid}/${competition}/${stage}/group/edit/${group.uuid}`}
                          >
                            <IconSettings width="12px" height="12px" fill="black" /> Editar
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          key={`competitions-group-delete-${group.uuid}`}
                          onClick={() => setGroupIdToRemove(group.uuid)}
                        >
                          <div>
                            <IconDelete width="12px" height="12px" fill="black" /> Apagar
                          </div>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Button
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      icon={<Icon3Dots width="15" height="15" />}
                      shape="circle"
                      type="text"
                    ></Button>
                  </Dropdown>
                </Wrapper>
              ))}
          </StyledUl>
          {!!groupIdToRemove && (
            <RemoveModal
              title={t('modals.sportsChampionshipGroupRemoveModal.title')}
              message={t('modals.sportsChampionshipGroupRemoveModal.message')}
              isLoading={isRemoving}
              showModal
              onClose={() => setGroupIdToRemove('')}
              onRemoveConfirm={() =>
                deleteGroup({
                  variables: {
                    uuid: groupIdToRemove,
                  },
                }).catch((error) => {
                  console.log('Error:', error);
                  setGroupIdToRemove('');
                })
              }
            />
          )}
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: center;
`;

const Loading = () => {
  return (
    <LoadingWrapper>
      <Spin />
    </LoadingWrapper>
  );
};
