import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';
import { MenuProps } from 'typings/menu';

import { HeaderMatchScore } from './headerMatchScore';
import { HeaderTeamGrid } from './headerTeamGrid';
import { MatchDetails } from './matchDetails';
import { Summary } from './summary';
import { Teams } from './teams/teams';
import { UpdateMatchScores } from './updateMatchScores';

import { PageHeader } from 'components/pageHeader';
import { RemoveModal } from 'components/removeModal';
import { TabsMenu } from 'components/tabsMenu';

import { IconDelete } from 'assets/icons/iconDelete';

import { DeleteChampionshipMatch, GetChampionshipMatch } from 'queries/sportsChampionshipMatch';
import {
  ChampionshipMatchEntity,
  ChampionshipMatchesClock,
  ChampionshipMatchEvent,
  ChampionshipSport,
  ConfigMatchEntityType,
  Mutation,
  MutationDeleteChampionshipMatchArgs,
  MutationUpdateChampionshipMatchEntitiesArgs,
  Query,
  QueryChampionshipMatchArgs,
} from 'queries/types';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

const HeaderScoreWrapper = styled.div`
  background: white;
  margin: -1px -25px 0;
  padding: 25px 0;
`;

const CompetitionDetails = styled.span`
  color: #9fa8b7;
  font-size: 12px;
  margin: 10px 0;
  text-align: center;
  display: block;
`;

const CompetitionDetailsGrid = styled(CompetitionDetails)`
  text-align: left;
  background: white;
  margin: -1px -25px 0;
  padding: 0 25px 25px;
`;

const Separator = styled.span`
  margin: 0 10px;
  display: inline-block;
`;

const TabsMenuWrapper = styled.div`
  margin: 0 -25px;

  > ul {
    padding: 0 25px;
  }
`;

const Content = styled.div`
  margin-top: 45px;
`;

const I18N_PREFIX = 'sportsMatch';

export const SportsChampionshipMatch = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { uuid, section } = useParams<{ uuid: string; section: string }>();

  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showUpdateScoresModal, setShowUpdateScoresModal] = useState<boolean>(false);

  const [getChampionshipMatch, { data, loading }] = useLazyQuery<Query, QueryChampionshipMatchArgs>(
    GetChampionshipMatch,
    {
      variables: {
        uuid,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getChampionshipMatch();
  }, [getChampionshipMatch]);

  // define refetch
  const refetch = {
    refetchQueries: [
      {
        query: GetChampionshipMatch,
        variables: {
          uuid,
        },
      },
    ],
    awaitRefetchQueries: true,
  };

  const [deleteChampionshipMatch, { loading: isRemoving, data: isRemoved }] = useMutation<
    Mutation,
    MutationDeleteChampionshipMatchArgs
  >(DeleteChampionshipMatch);

  useEffect(() => {
    if (isRemoved) history.push(ROUTES.SportsChampionships);
  }, [isRemoved]);

  const routes = [
    {
      path: ROUTES.SportsChampionships,
      breadcrumbName: t(`tabsMenu.sports.championships`),
    },
  ];

  if (!data) return null;

  const group = data.championshipMatch.championshipGroup;
  const stage = group.championshipStage;
  const competition = stage.championshipCompetition;

  const isMatchGrid =
    data.championshipMatch.championshipGroup.championshipStage.championshipCompetition
      .championshipSport.configMatchEntityType === ConfigMatchEntityType.Grid;

  const baseUrl = `${ROUTES.SportsMatch}/${uuid}`;

  const tabsMenu: MenuProps[] = [
    {
      key: `${baseUrl}/teams`,
      path: `${baseUrl}/teams`,
      title: `${I18N_PREFIX}.sections.teams.tab`,
      icon: '',
    },
    {
      key: `${baseUrl}/match-details`,
      path: `${baseUrl}/match-details`,
      title: `${I18N_PREFIX}.sections.matchDetails.tab`,
      icon: '',
    },
  ];

  if (!isMatchGrid) {
    tabsMenu.unshift({
      key: `${baseUrl}/summary`,
      path: `${baseUrl}/summary`,
      title: `${I18N_PREFIX}.sections.summary.tab`,
      icon: '',
    });
  }

  let component;
  switch (section) {
    case 'teams':
      component = (
        <Teams
          teams={data.championshipMatch.championshipMatchEntities as ChampionshipMatchEntity[]}
          refetch={refetch}
          loading={loading}
          isMatchGrid={isMatchGrid}
          group={group}
        />
      );
      break;
    case 'summary':
      component = !isMatchGrid ? (
        <Summary
          parts={(data.championshipMatch.clock || []) as ChampionshipMatchesClock[]}
          events={data.championshipMatch.championshipMatchEvents as ChampionshipMatchEvent[]}
          championshipMatch={data.championshipMatch}
          refetch={refetch}
        />
      ) : null;
      break;
    case 'match-details':
      component = <MatchDetails championshipMatch={data.championshipMatch} refetch={refetch} />;
      break;
  }

  return (
    <>
      <StyledPageHeader
        title={t(`${I18N_PREFIX}.title`)}
        breadcrumbs={{ routes, separator: '/' }}
        whiteBackground={true}
      >
        <div>
          {!isMatchGrid && (
            <Button className="mr-2" onClick={() => setShowUpdateScoresModal(true)}>
              {t(`${I18N_PREFIX}.actions.edit.score`)}
            </Button>
          )}
          <Button
            onClick={() => setShowRemoveModal(true)}
            icon={<IconDelete width="13" height="13" className="anticon" />}
          >
            {t(`${I18N_PREFIX}.actions.remove`)}
          </Button>
        </div>
      </StyledPageHeader>
      {!isMatchGrid ? (
        <HeaderScoreWrapper>
          <>
            <HeaderMatchScore match={data.championshipMatch} />
            <CompetitionDetails>
              {competition.name.pt}
              <Separator>/</Separator>
              {stage.name.pt}
              <Separator>/</Separator>
              {group.name.pt}
            </CompetitionDetails>
          </>
        </HeaderScoreWrapper>
      ) : null}

      {isMatchGrid ? (
        <>
          <CompetitionDetailsGrid>
            {competition.name.pt}
            <Separator>/</Separator>
            {stage.name.pt}
            <Separator>/</Separator>
            {group.name.pt}
          </CompetitionDetailsGrid>
          <HeaderTeamGrid
            teams={data.championshipMatch.championshipMatchEntities as ChampionshipMatchEntity[]}
            loading={false}
            refetch={refetch}
            championshipSport={
              data.championshipMatch.championshipGroup.championshipStage.championshipCompetition
                .championshipSport as ChampionshipSport
            }
          />
        </>
      ) : null}
      <TabsMenuWrapper>
        <TabsMenu data={tabsMenu} />
      </TabsMenuWrapper>
      <Content>{component}</Content>
      {showRemoveModal && (
        <RemoveModal
          title={t('modals.sportsMatchRemoveModal.title')}
          message={t('modals.sportsMatchRemoveModal.message')}
          isLoading={isRemoving}
          showModal
          onClose={() => setShowRemoveModal(false)}
          onRemoveConfirm={() =>
            deleteChampionshipMatch({
              variables: {
                uuid,
              },
            })
          }
        />
      )}
      <UpdateMatchScores
        showModal={showUpdateScoresModal}
        onClose={() => setShowUpdateScoresModal(false)}
        match={data.championshipMatch}
        refetch={refetch}
      />
    </>
  );
};
