import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Modal, Table } from 'antd';
import { ITEMS_PER_PAGE } from 'config/main';
import { getTablePagination } from 'helpers/tablePagination';
import moment from 'moment';
import { Store } from 'rc-field-form/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchField } from 'components/searchField';

import { GetChampionshipEntityPlayers } from 'queries/championshipEntityPlayers';
import { AddChampionshipMatchEntityPlayer } from 'queries/sportsChampionshipMatchEntityPlayer';
import { GetPlayers } from 'queries/sportsPlayers';
import { AddTeamToPlayer } from 'queries/sportsPlayers';
import { GetSportsTeam } from 'queries/sportsTeamsMutations';
import {
  ChampionshipEntityPlayer,
  ChampionshipMatchEntity,
  ChampionshipMatchEntityPlayer,
  Mutation,
  MutationAddChampionshipMatchEntityPlayerArgs,
  MutationAddTeamToPlayerArgs,
  Pagination,
  Player,
  Query,
  QueryChampionshipEntityPlayersArgs,
  QueryPlayersArgs,
} from 'queries/types';

interface Props {
  isLoading: boolean;
  showModal: boolean;
  onClose: () => void;
  team: ChampionshipMatchEntity;
  refetch: any;
}

export const TeamPlayersAddModal = ({ isLoading, showModal, onClose, team, refetch }: Props) => {
  const { t } = useTranslation();

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const variables: QueryChampionshipEntityPlayersArgs = {
    championshipEntityUuid: team.championshipEntity.uuid,
    pagination: {
      page: selectedPage,
      take: ITEMS_PER_PAGE,
    },
  };

  const [getPlayers, { data }] = useLazyQuery<Query, QueryChampionshipEntityPlayersArgs>(
    GetChampionshipEntityPlayers,
    {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getPlayers();
  }, []);

  const [addTeamToPlayer, { data: isAdded, loading: isAdding }] = useMutation<
    Mutation,
    MutationAddChampionshipMatchEntityPlayerArgs
  >(AddChampionshipMatchEntityPlayer, refetch);

  useEffect(() => {
    if (isAdded) handleCancel();
  }, [isAdded]);

  /**
   * Handles confirm button click
   * Calls onRemoveConfirm method that fires the mutation
   */
  const handleOk = () => {
    const player = list.find((a) => a.uuid === selectedRowKeys[0]) as ChampionshipEntityPlayer;

    if (player.uuid) {
      addTeamToPlayer({
        variables: {
          championshipEntityPlayers: [
            {
              uuid: player.uuid,
            },
          ],
          championshipMatchEntityUuid: team.uuid,
        },
      });
    }
  };

  /**
   * Handles modal close button click
   * Closes the modal without any mutation happening
   */
  const handleCancel = useCallback(() => {
    setSelectedRowKeys([]);
    onClose();
  }, [onClose]);

  const tableColumns = [
    {
      title: '',
      dataIndex: 'player',
      render: (player: Player) => (
        <span>
          {player.firstName} {player.lastName}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'player',
      render: (player: Player) => <span>{player.identification}</span>,
    },
    {
      title: '',
    },
  ];

  const list = (data?.championshipEntityPlayers.edges as ChampionshipEntityPlayer[]) || [];
  const pagination = data?.championshipEntityPlayers.pagination as Pagination;

  if (!showModal) return null;

  return (
    <Modal
      width="960px"
      title={t('sportsTeamsPlayersModal.title')}
      visible
      onCancel={handleCancel}
      centered={true}
      footer={[
        <Button key="reset" loading={isAdding} onClick={handleCancel}>
          {t('actions.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isAdding}
          onClick={handleOk}
          disabled={selectedRowKeys.length === 0}
        >
          {t('actions.confirm')}
        </Button>,
      ]}
    >
      <Table
        columns={tableColumns}
        dataSource={list}
        //   loading={loading || mutationLoading}
        rowKey="uuid"
        pagination={getTablePagination(pagination, setSelectedPage)}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          type: 'radio',
          preserveSelectedRowKeys: false,
          onChange: (selectedKeys: any) => {
            setSelectedRowKeys(selectedKeys);
          },
        }}
      />
    </Modal>
  );
};
