import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TeamPlayersAddModal } from '../teamPlayersAddModal';
import { TeamsAddModal } from '../teamsAddModal';
import { TeamsGrid } from './teamsGrid';
import { TeamsPair } from './teamsPair';

import { RemoveModal } from 'components/removeModal';

import {
  DeleteChampionshipMatchEntityPlayer,
  UpdateChampionshipMatchEntityPlayer,
} from 'queries/sportsChampionshipMatchEntityPlayer';
import { DeleteChampionshipMatchEntity } from 'queries/sportsChampionshipMatchTeams';
import {
  ChampionshipEntity,
  ChampionshipEntityPlayer,
  ChampionshipGroup,
  ChampionshipMatchEntity,
  ChampionshipMatchEntityPlayerType,
  Mutation,
  MutationDeleteChampionshipMatchEntityArgs,
  MutationDeleteChampionshipMatchEntityPlayerArgs,
  MutationUpdateChampionshipMatchEntityPlayerArgs,
} from 'queries/types';

const Container = styled.div`
  display: flex;
`;

interface Props {
  teams: ChampionshipMatchEntity[];
  refetch: any;
  loading: boolean;
  isMatchGrid: boolean;
  group: ChampionshipGroup;
}

export const Teams = ({ teams, refetch, loading, isMatchGrid, group }: Props) => {
  const { t } = useTranslation();

  const [showPlayerRemoveModal, setShowPlayerRemoveModal] = useState<boolean>(false);
  const [playerToRemove, setPlayerToRemove] = useState<string>();
  const [showPlayerAddModal, setShowPlayerAddModal] = useState<boolean>(false);
  const [teamToAdd, setTeamToAdd] = useState<ChampionshipMatchEntity>();
  const [showTeamRemoveModal, setShowTeamRemoveModal] = useState<boolean>(false);
  const [teamToRemove, setTeamToRemove] = useState<string>();
  const [showTeamsAddModal, setShowTeamsAddModal] = useState<boolean>(false);

  const [setMatchEntityPlayerSubstitute, { loading: updatingPlayer }] = useMutation<
    Mutation,
    MutationUpdateChampionshipMatchEntityPlayerArgs
  >(UpdateChampionshipMatchEntityPlayer, refetch);

  const [deleteMatchEntityPlayer, { loading: isDeleting }] = useMutation<
    Mutation,
    MutationDeleteChampionshipMatchEntityPlayerArgs
  >(DeleteChampionshipMatchEntityPlayer, refetch);

  const [deleteMatchEntityTeam, { loading: isDeletingTeam }] = useMutation<
    Mutation,
    MutationDeleteChampionshipMatchEntityArgs
  >(DeleteChampionshipMatchEntity, refetch);

  const setSubstitute = (player: ChampionshipEntityPlayer) => {
    updatePlayer(player, undefined, true);
  };

  const setNumber = (player: ChampionshipEntityPlayer, e: any) => {
    updatePlayer(player, e.target.value);
  };

  const updatePlayer = (
    player: ChampionshipEntityPlayer,
    number?: string,
    substitute?: boolean
  ) => {
    if (player.championshipMatchEntityPlayers && player.championshipMatchEntityPlayers[0]) {
      const playerData = player.championshipMatchEntityPlayers[0];

      setMatchEntityPlayerSubstitute({
        variables: {
          championshipMatchEntityPlayers: [
            {
              uuid: playerData.uuid,
              number: number ? number : playerData.number,
              type: substitute
                ? playerData.type === ChampionshipMatchEntityPlayerType.PlayerSubstitute
                  ? ChampionshipMatchEntityPlayerType.Player
                  : ChampionshipMatchEntityPlayerType.PlayerSubstitute
                : playerData.type,
            },
          ],
        },
      });
    }
  };

  const removePlayer = (uuid: string) => {
    setPlayerToRemove(uuid);
    setShowPlayerRemoveModal(true);
  };

  const closeRemovePlayer = () => {
    setShowPlayerRemoveModal(false);
    setPlayerToRemove('');
  };

  const removeTeam = (uuid: string) => {
    setTeamToRemove(uuid);
    setShowTeamRemoveModal(true);
  };

  const closeRemoveTeam = () => {
    setShowTeamRemoveModal(false);
    setTeamToRemove('');
  };

  return (
    <Container>
      {isMatchGrid ? (
        <TeamsGrid
          teams={teams}
          loading={updatingPlayer || loading}
          setNumber={setNumber}
          setSubstitute={setSubstitute}
          removePlayer={removePlayer}
          setTeamToAdd={setTeamToAdd}
          setShowPlayerAddModal={setShowPlayerAddModal}
          setShowTeamsAddModal={setShowTeamsAddModal}
          removeTeam={removeTeam}
        />
      ) : (
        <TeamsPair
          teams={teams}
          loading={updatingPlayer || loading}
          setNumber={setNumber}
          setSubstitute={setSubstitute}
          removePlayer={removePlayer}
          setTeamToAdd={setTeamToAdd}
          setShowPlayerAddModal={setShowPlayerAddModal}
        />
      )}

      {showPlayerRemoveModal && (
        <RemoveModal
          title={t('sportsMatch.sections.teams.remove.title')}
          message={t('sportsMatch.sections.teams.remove.message')}
          showModal
          isLoading={isDeleting}
          onClose={closeRemovePlayer}
          onRemoveConfirm={() => {
            deleteMatchEntityPlayer({
              variables: {
                uuid: playerToRemove,
              },
            })
              .catch((e) => console.error(e))
              .finally(() => {
                closeRemovePlayer();
              });
          }}
        />
      )}

      {showTeamRemoveModal && (
        <RemoveModal
          title={t('sportsMatch.sections.teams.removeTeam.title')}
          message={t('sportsMatch.sections.teams.removeTeam.message')}
          showModal
          isLoading={isDeletingTeam}
          onClose={closeRemoveTeam}
          onRemoveConfirm={() => {
            deleteMatchEntityTeam({
              variables: {
                uuid: teamToRemove,
              },
            })
              .catch((e) => console.error(e))
              .finally(() => {
                closeRemoveTeam();
              });
          }}
        />
      )}

      {teamToAdd && (
        <TeamPlayersAddModal
          showModal={showPlayerAddModal}
          onClose={() => setShowPlayerAddModal(false)}
          isLoading={false}
          team={teamToAdd}
          refetch={refetch}
        />
      )}

      <TeamsAddModal
        showModal={showTeamsAddModal}
        onClose={() => setShowTeamsAddModal(false)}
        isLoading={false}
        teams={group.championshipEntities as ChampionshipEntity[]}
        refetch={refetch}
      />
    </Container>
  );
};
