import { useMutation, useQuery } from '@apollo/client';
import { Button, Spin } from 'antd';
import { ITEMS_PER_PAGE } from 'config/main';
import { reorder } from 'helpers/array';
import { sortByPosition } from 'helpers/posts';
import { useHighlightToggleMutation } from 'hooks/useHighlightToggleMutation';
import { useOrderingMutation } from 'hooks/useOrderingMutation';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { HighlightsItem } from './HighlightsItem';
import { HighlightsModal } from './HighlightsModal';

import { PageHeader } from 'components/pageHeader';
import { RemoveModal } from 'components/removeModal';

import { IconPlus } from 'assets/icons/iconPlus';

import { UpdateHighlightStretch } from 'queries/highlightMutations';
import { GetPostListQuery } from 'queries/postsList';
import {
  QueryFeedPostsArgs,
  FeedPost,
  Query,
  Mutation,
  MutationStretchHighlightFeedPostArgs,
} from 'queries/types';

const Board = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
`;

const Body = styled.div`
  padding: 24px 24px 24px 32px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const variables = {
  filter: {
    type: [],
    highlighted: true,
  },
  pagination: {
    page: 1,
    take: ITEMS_PER_PAGE,
  },
};

export const Highlights = () => {
  const { t } = useTranslation();
  const { loading, data, refetch } = useQuery<Query, QueryFeedPostsArgs>(GetPostListQuery, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [posts, setPosts] = useState<FeedPost[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [highlightToRemove, setHighlightToRemove] = useState('');

  const { saveOrder, isOrdering } = useOrderingMutation();
  const { addHighlight, removeHighlight, isAdding, isRemoving } = useHighlightToggleMutation();

  useEffect(() => {
    const edges = (data?.feedPosts?.edges as FeedPost[]) || [];
    setPosts([...edges].sort(sortByPosition));
  }, [data?.feedPosts.edges.length]);

  useEffect(() => {
    if (posts.length > 0) {
      saveOrder(posts.map((post, idx) => ({ uuid: post.uuid, position: idx })));
    }
  }, [posts]);

  /**
   * Handles list reordering when drag ends
   */
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination || isOrdering) {
        return; // dropped outside the list
      }
      setPosts(reorder(posts, result.source.index, result.destination.index));
    },
    [posts]
  );

  /**
   * Called when the "Add Highlight" button is clicked
   * Shows the HighlightModal component
   */
  const onAddHighlightClick = useCallback(() => {
    setShowModal(true);
  }, []);

  /**
   * Called when the user picks an highlight to add inside the highlights modal
   */
  const onAddConfirmClick = useCallback(
    (uuid: string) => {
      addHighlight(uuid, () => {
        setShowModal(false);
        refetch();
      });
    },
    [addHighlight]
  );

  /**
   * Called when the user confirms to remove an highlight inside the highlights remove modal
   */
  const onRemoveConfirmClick = useCallback(() => {
    removeHighlight(highlightToRemove, () => {
      setHighlightToRemove('');
      refetch();
    });
  }, [removeHighlight, highlightToRemove]);

  /**
   * Called when the delete icon is clicked inside the Highlight
   * Shows the HighlightRemoveModal component
   * @param uuid The clicked item uuid
   */
  const onRemoveButtonClick = useCallback((uuid: string) => {
    setHighlightToRemove(uuid);
  }, []);

  /**
   * Mutation to update stretch property
   */
  const [setStretch] = useMutation<Mutation, MutationStretchHighlightFeedPostArgs>(
    UpdateHighlightStretch
  );

  const setHighlightStretch = (uuid: string, stretch: boolean) => {
    setStretch({
      variables: {
        uuid,
        stretch,
      },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <HeaderWrapper>
        <PageHeader title="Destaques" />

        <Button
          onClick={onAddHighlightClick}
          type="primary"
          icon={<IconPlus className={'anticon'} width="15" height="16" />}
        >
          {t('highlights.addHighlight')}
        </Button>
      </HeaderWrapper>

      {/* HIGHLIGHTS PICKER MODAL */}
      <HighlightsModal
        isAdding={isAdding}
        showModal={showModal}
        setShowModal={setShowModal}
        onAddConfirm={onAddConfirmClick}
      />

      {/* HIGHLIGHTS REMOVE MODAL */}
      {!!highlightToRemove && (
        <RemoveModal
          title={t('modals.removeHighlight.title')}
          message={t('modals.removeHighlight.message')}
          isLoading={isRemoving}
          showModal
          onRemoveConfirm={onRemoveConfirmClick}
          onClose={() => setHighlightToRemove('')}
        />
      )}

      {/* CARDS BOARD */}
      {loading ? (
        <LoadingWrapper>
          <Spin size="large" />
        </LoadingWrapper>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <Board className="scrumboard" ref={provided.innerRef} {...provided.droppableProps}>
                <Body className="scrumboard-body">
                  {posts.map((item, idx) => {
                    return (
                      <HighlightsItem
                        key={item.uuid}
                        index={idx}
                        {...item}
                        setHighlightStretch={setHighlightStretch}
                        onRemoveClick={onRemoveButtonClick}
                      />
                    );
                  })}
                  {provided.placeholder}
                </Body>
              </Board>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};
