import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Table } from 'antd';
import { ADS_PER_PAGE } from 'config/main';
import { getCreateAdvertisement } from 'containers/advertisement/utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { advertisementPagesPosition, PagesPosition } from './config';

import { DateStatusTag } from 'components/dateStatusTag';
import { RemoveModal } from 'components/removeModal';

import { IconDelete } from 'assets/icons/iconDelete';
import { IconPlus } from 'assets/icons/iconPlus';

import { DeleteAdvertisement } from 'queries/advertisementMutations';
import { GetPostListQuery } from 'queries/postsList';
import {
  FeedPost,
  FeedPostAdvertisement,
  FeedPostOrigin,
  FeedPostPage,
  Mutation,
  MutationDeleteFeedPostArgs,
  Query,
  QueryFeedPostsArgs,
  Scalars,
} from 'queries/types';

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledH4 = styled.h4`
  margin: 0;
`;

/**
 * CONSTANTS
 */
const I18N_PREFIX: string = 'advertisementList';

export const AdvertisementList = () => {
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [selectedAdvertisement, setSelectedAdvertisement] = useState<string>('');

  const { t } = useTranslation();

  const variables: QueryFeedPostsArgs = {
    filter: {
      origin: [FeedPostOrigin.Pub],
    },
    pagination: {
      page: 1,
      take: ADS_PER_PAGE,
    },
  };

  const [deleteAdvertisement, { data: isDeleted, loading: isDeleting }] = useMutation<
    Mutation,
    MutationDeleteFeedPostArgs
  >(DeleteAdvertisement);

  useEffect(() => {
    if (isDeleted) {
      setShowRemoveModal(false);
      setSelectedAdvertisement('');
    }
  }, [isDeleted]);

  const [getAdvertisements, { data, loading }] = useLazyQuery<Query, QueryFeedPostsArgs>(
    GetPostListQuery,
    {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getAdvertisements();
  }, [getAdvertisements, isDeleted]);

  const advertisementsPosts = (data?.feedPosts.edges as FeedPost[]) || [];

  const tableColumns = [
    {
      title: t(`${I18N_PREFIX}.title`),
      dataIndex: 'note',
      render: (note: Scalars['TranslatableField'], post: FeedPost) => (
        <span className="font-weight-bold">
          <Link to={`${ROUTES.Advertisement}${ROUTES.Edit}/${post.uuid}`}>
            {(note && note.pt) || post.title.pt || '<Sem Título>'}
          </Link>
        </span>
      ),
      width: '50%',
    },
    {
      title: t(`${I18N_PREFIX}.startDate`),
      dataIndex: 'advertisement',
      render: (advertisement: FeedPostAdvertisement) => (
        <span>{moment(advertisement.startDate).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      title: t(`${I18N_PREFIX}.endDate`),
      dataIndex: 'advertisement',
      render: (advertisement: FeedPostAdvertisement) => (
        <span>{moment(advertisement.endDate).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      dataIndex: 'advertisement',
      render: (advertisement: FeedPostAdvertisement) => (
        <DateStatusTag advertisement={advertisement} />
      ),
    },
    {
      dataIndex: 'advertisement',
      render: (advertisement: FeedPostAdvertisement, post: FeedPost) => (
        <Button
          onClick={() => {
            setShowRemoveModal(true);
            setSelectedAdvertisement(post.uuid);
          }}
          icon={<IconDelete width="15" height="15" />}
          shape="circle"
        />
      ),
    },
  ];

  const history = useHistory();
  const goToCreatePage = (page: FeedPostPage, position: number) =>
    history.push(`${getCreateAdvertisement(page, position)}`);

  return (
    <>
      {advertisementPagesPosition.map((item: PagesPosition) => {
        const pageAds = advertisementsPosts.filter(
          (post: FeedPost) =>
            post.advertisement?.page === item.page && post.advertisement?.position === item.position
        );

        return (
          <Card key={`${item.title} ${item.position}`}>
            <CardHeader>
              <StyledH4>{t(`${I18N_PREFIX}.pages.${item.title}`)}</StyledH4>
              <Button
                onClick={() => goToCreatePage(item.page, item.position)}
                type="primary"
                icon={<IconPlus className={'anticon'} width="15" height="16" />}
              >
                {t(`${I18N_PREFIX}.actions.create`)}
              </Button>
            </CardHeader>
            <Table columns={tableColumns} loading={loading} dataSource={pageAds} />
          </Card>
        );
      })}
      {showRemoveModal && (
        <RemoveModal
          title={t('modals.advertisementRemoveModal.title')}
          message={t('modals.advertisementRemoveModal.message')}
          showModal
          isLoading={isDeleting}
          onClose={() => setShowRemoveModal(false)}
          onRemoveConfirm={() => {
            deleteAdvertisement({
              variables: {
                uuid: selectedAdvertisement,
              },
            });
          }}
        />
      )}
    </>
  );
};
