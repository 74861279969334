import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Modal, Form, Select, Input, Spin } from 'antd';
import { ALL_ITEMS_ON_PAGE } from 'config/main';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  championshipEventsToOptions,
  generateAddEventFields,
  getFormInititalValues,
  isFieldMandatory,
  isFieldToShow,
  milisecondToMinutes,
  playersToOptions,
  teamsToOptions,
  transformFormValues,
} from './utils';

import {
  CreateChampionshipMatchEvent,
  GetChampionshipEvents,
} from 'queries/sportsChampionshipEvents';
import { GetChampionshipMatch } from 'queries/sportsChampionshipMatch';
import { GetPlayers } from 'queries/sportsPlayers';
import { GetTeams } from 'queries/sportsTeamsList';
import {
  ChampionshipEvent,
  ChampionshipMatch,
  ChampionshipMatchEntity,
  ChampionshipMatchEntityPlayer,
  ClockDirection,
  Mutation,
  MutationChampionshipMatchEventCreateArgs,
  Player,
  Query,
  QueryChampionshipEventsArgs,
  QueryPlayersArgs,
  QueryTeamsArgs,
  Team,
  TeamList,
} from 'queries/types';

const I18N_PREFIX_FIELDS = 'sportsMatch.sections.summary.event.addEventFields';

const StyledButton = styled(Button)`
  justify-content: center;
`;

const FormHeader = styled.div`
  margin-bottom: 50px;
  padding-bottom: 26px;
  border-bottom: 1px solid #9fa8b7;
  display: flex;

  & > div {
    margin-right: 10px;

    &:last-of-type {
      width: 25%;
      margin-right: 0;
    }

    &:first-of-type {
      width: 75%;
    }
  }
`;

interface Props {
  showModal: boolean;
  onClose: () => void;
  championshipSport: string;
  championshipMatch: ChampionshipMatch;
  timeForEvent: number;
  timeDirection: ClockDirection;
  timeOfMatch: any;
  defaultEventKey: string;
}

export const AddEventModal = ({
  showModal,
  onClose,
  championshipSport,
  championshipMatch,
  timeForEvent,
  timeDirection,
  timeOfMatch,
  defaultEventKey,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [selectedEventKey, setSelectedEventKey] = useState<string>(defaultEventKey);
  const [selectedChampionshipMatchEntity, setSelectedChampionshipMatchEntity] = useState<string>();
  const [selectedChampionshipMatchEntityPlayer, setSelectedChampionshipMatchEntityPlayer] =
    useState<string | null>();
  const [selectedAuxChampionshipMatchEntityPlayer, setSelectedAuxChampionshipMatchEntityPlayer] =
    useState<string | null>();

  /**
   * Rest player fields, if selected team changes
   */
  useEffect(() => {
    setSelectedChampionshipMatchEntityPlayer(null);
    form.setFieldsValue({
      championshipMatchEntityPlayerUuid: undefined,
    });

    setSelectedAuxChampionshipMatchEntityPlayer(null);
    form.setFieldsValue({
      auxChampionshipMatchEntityPlayerUuid: undefined,
    });
  }, [selectedChampionshipMatchEntity]);

  const variables: QueryChampionshipEventsArgs = {
    filter: {
      championshipSportUuid: championshipSport,
    },
    pagination: {
      take: ALL_ITEMS_ON_PAGE,
    },
  };

  const [getChampionshipEvents, { data }] = useLazyQuery<Query, QueryChampionshipEventsArgs>(
    GetChampionshipEvents,
    {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getChampionshipEvents();
  }, []);

  useEffect(() => {
    if (!selectedEventKey) {
      setSelectedEventKey(
        (data?.championshipEvents.edges[0] && data?.championshipEvents.edges[0].key) || ''
      );
    }
  }, [data]);

  const [createMatchEvent, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationChampionshipMatchEventCreateArgs
  >(CreateChampionshipMatchEvent, {
    refetchQueries: [
      {
        query: GetChampionshipMatch,
        variables: {
          uuid: championshipMatch.uuid,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (isCreated) onClose();
  }, [isCreated]);

  const onFinish = async (values: any) => {
    createMatchEvent({
      variables: {
        uuid: championshipMatch.uuid,
        eventData: transformFormValues(championshipEventSelected, values),
      },
    })
      .then()
      .catch((e) => {
        console.error(e);
      });
  };

  /**
   * Handles confirm button click
   * Calls onRemoveConfirm method that fires the mutation
   */
  const handleOk = () => {
    form.submit();
  };

  /**
   * Handles modal close button click
   * Closes the modal without any mutation happening
   */
  const handleCancel = useCallback(() => {
    if (!isCreating) {
      onClose();
    }
  }, [onClose]);

  const championshipEvents = data?.championshipEvents.edges;
  const championshipEventSelected = championshipEvents
    ? championshipEvents.find((a) => a?.key === selectedEventKey)
    : null;

  const playersForSelect = ((championshipMatch?.championshipMatchEntities &&
    championshipMatch?.championshipMatchEntities.find(
      (a) => a?.uuid === selectedChampionshipMatchEntity
    )?.championshipMatchEntityPlayers) ||
    []) as ChampionshipMatchEntityPlayer[];

  if (!showModal) return null;

  return (
    <Modal
      width="960px"
      title={t('sportsMatch.sections.summary.event.addEvent')}
      visible
      onCancel={handleCancel}
      centered={true}
      footer={[
        <StyledButton
          htmlType="submit"
          type="primary"
          block
          loading={isCreating}
          onClick={handleOk}
        >
          {t('actions.confirm')}
        </StyledButton>,
      ]}
    >
      {!!championshipEvents && !!championshipEventSelected ? (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={getFormInititalValues(
            championshipEventSelected,
            timeOfMatch,
            defaultEventKey
          )}
        >
          <FormHeader>
            <Form.Item
              name="championshipEventKey"
              label={t(`${I18N_PREFIX_FIELDS}.championshipEventKey`)}
              rules={[
                {
                  required: true,
                  message: t(`${I18N_PREFIX_FIELDS}.errorMessage`),
                },
              ]}
            >
              <Select
                className="w-100"
                onChange={setSelectedEventKey as any}
                options={championshipEventsToOptions(championshipEvents as ChampionshipEvent[])}
              />
            </Form.Item>
            {isFieldToShow(championshipEventSelected, 'time') && (
              <Form.Item
                name="time"
                label={t(`${I18N_PREFIX_FIELDS}.time`)}
                rules={[
                  {
                    required: isFieldMandatory(championshipEventSelected, 'time'),
                    message: t(`${I18N_PREFIX_FIELDS}.errorMessage`),
                  },
                  {
                    validator(_rule, value, callback) {
                      if (value !== '' || isFieldMandatory(championshipEventSelected, 'time')) {
                        switch (timeDirection) {
                          case ClockDirection.Asc:
                            if (value < milisecondToMinutes(timeForEvent)) {
                              callback(t(`${I18N_PREFIX_FIELDS}.minValue`));
                            }
                            break;
                          case ClockDirection.Desc:
                            if (value > milisecondToMinutes(timeForEvent)) {
                              callback(t(`${I18N_PREFIX_FIELDS}.maxValue`));
                            }
                            break;
                        }
                      }

                      callback();
                    },
                  },
                ]}
              >
                <Input
                  type="number"
                  min={
                    timeDirection === ClockDirection.Asc
                      ? milisecondToMinutes(timeForEvent) + 1
                      : undefined
                  }
                  max={
                    timeDirection === ClockDirection.Desc
                      ? milisecondToMinutes(timeForEvent) + 1
                      : undefined
                  }
                  placeholder={t(`${I18N_PREFIX_FIELDS}.time`)}
                />
              </Form.Item>
            )}
          </FormHeader>
          {isFieldToShow(championshipEventSelected, 'championshipMatchEntityUuid') &&
            championshipMatch.championshipMatchEntities && (
              <Form.Item
                name="championshipMatchEntityUuid"
                label={t(`${I18N_PREFIX_FIELDS}.championshipMatchEntityUuid`)}
                rules={[
                  {
                    required: isFieldMandatory(
                      championshipEventSelected,
                      'championshipMatchEntityUuid'
                    ),
                    message: t(`${I18N_PREFIX_FIELDS}.errorMessage`),
                  },
                ]}
              >
                <Select
                  className="w-100"
                  onChange={(value) => setSelectedChampionshipMatchEntity(value as string)}
                  options={teamsToOptions(
                    championshipMatch.championshipMatchEntities as ChampionshipMatchEntity[]
                  )}
                  // value={selectedChampionshipMatchEntity}
                />
              </Form.Item>
            )}
          {isFieldToShow(championshipEventSelected, 'championshipMatchEntityPlayerUuid') && (
            <Form.Item
              name="championshipMatchEntityPlayerUuid"
              label={t(`${I18N_PREFIX_FIELDS}.championshipMatchEntityPlayerUuid`)}
              rules={[
                {
                  required: isFieldMandatory(
                    championshipEventSelected,
                    'championshipMatchEntityPlayerUuid'
                  ),
                  message: t(`${I18N_PREFIX_FIELDS}.errorMessage`),
                },
              ]}
            >
              <Select
                className="w-100"
                onChange={(value) => setSelectedChampionshipMatchEntityPlayer(value as string)}
                options={playersToOptions(playersForSelect)}
                value={selectedChampionshipMatchEntityPlayer || undefined}
                disabled={
                  isFieldToShow(championshipEventSelected, 'championshipMatchEntityUuid') &&
                  isFieldMandatory(championshipEventSelected, 'championshipMatchEntityUuid') &&
                  !selectedChampionshipMatchEntity
                }
              />
            </Form.Item>
          )}
          {isFieldToShow(championshipEventSelected, 'auxChampionshipMatchEntityPlayerUuid') && (
            <Form.Item
              name="auxChampionshipMatchEntityPlayerUuid"
              label={t(`${I18N_PREFIX_FIELDS}.auxChampionshipMatchEntityPlayerUuid`)}
              rules={[
                {
                  required: isFieldMandatory(
                    championshipEventSelected,
                    'auxChampionshipMatchEntityPlayerUuid'
                  ),
                  message: t(`${I18N_PREFIX_FIELDS}.errorMessage`),
                },
              ]}
            >
              <Select
                className="w-100"
                onChange={(value) => setSelectedAuxChampionshipMatchEntityPlayer(value as string)}
                options={playersToOptions(playersForSelect)}
                value={selectedAuxChampionshipMatchEntityPlayer || undefined}
              />
            </Form.Item>
          )}
          {isFieldToShow(championshipEventSelected, 'title') && (
            <Form.Item
              name="title"
              label={t(`${I18N_PREFIX_FIELDS}.title`)}
              rules={[
                {
                  required: isFieldMandatory(championshipEventSelected, 'title'),
                  message: t(`${I18N_PREFIX_FIELDS}.errorMessage`),
                },
              ]}
            >
              <Input placeholder={t(`${I18N_PREFIX_FIELDS}.title`)} />
            </Form.Item>
          )}
          {isFieldToShow(championshipEventSelected, 'subtitle') && (
            <Form.Item
              name="subtitle"
              label={t(`${I18N_PREFIX_FIELDS}.subtitle`)}
              rules={[
                {
                  required: isFieldMandatory(championshipEventSelected, 'subtitle'),
                  message: t(`${I18N_PREFIX_FIELDS}.errorMessage`),
                },
              ]}
            >
              <Input placeholder={t(`${I18N_PREFIX_FIELDS}.subtitle`)} />
            </Form.Item>
          )}
          {isFieldToShow(championshipEventSelected, 'value') && (
            <Form.Item
              name="value"
              label={t(`${I18N_PREFIX_FIELDS}.value`)}
              rules={[
                {
                  required: isFieldMandatory(championshipEventSelected, 'value'),
                  message: t(`${I18N_PREFIX_FIELDS}.errorMessage`),
                },
              ]}
            >
              <Input type="number" placeholder={t(`${I18N_PREFIX_FIELDS}.value`)} />
            </Form.Item>
          )}
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: center;
`;

const Loading = () => {
  return (
    <LoadingWrapper>
      <Spin />
    </LoadingWrapper>
  );
};
