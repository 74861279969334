import { useLazyQuery, useMutation } from '@apollo/client';
import { Card, Dropdown, Table, Menu, Button, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { SportsChampionshipGroupTeamsModal } from './sportsChampionshipGroupTeamsModal';

import { RemoveModal } from 'components/removeModal';

import { Icon3Dots } from 'assets/icons/icon3Dots';
import { IconDelete } from 'assets/icons/iconDelete';
import { IconFeed } from 'assets/icons/iconFeed';
import { IconPlus } from 'assets/icons/iconPlus';

import {
  DeleteChampionshipEntities,
  UpdateChampionshipEntityWeight,
} from 'queries/sportsChampionshipGroup';
import {
  ChampionshipEntity,
  ChampionshipTeam,
  Mutation,
  MutationChampionshipEntityUpdateWeightArgs,
  MutationDeleteChampionshipEntitiesArgs,
} from 'queries/types';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const StyledButton = styled(Button)`
  align-self: end;
  margin-bottom: 16px;
`;

const ColorsWrapper = styled.span`
  display: flex;
`;

const Color = styled.span`
  width: 10px;
  height: 5px;
  display: inline-block;
`;

const TeamWrapper = styled.span`
  font-weight: bold;
`;

const StyledInput = styled(Input)`
  text-align: center;
  width: 40px;
`;

const I18N_PREFIX: string = 'sportsChampionshipGroupForm';

interface Props {
  teams: ChampionshipEntity[];
  section?: string;
  refetch: any;
}

export const SportsChampionshipGroupTeams: React.VFC<Props> = ({ teams, refetch }: Props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<string>('');

  const { uuid: championshipUrl, group } = useParams<{
    uuid: string;
    group: string;
  }>();

  const [deleteTeam, { loading: isRemoving, data: isRemoved }] = useMutation<
    Mutation,
    MutationDeleteChampionshipEntitiesArgs
  >(DeleteChampionshipEntities, refetch);

  useEffect(() => {
    if (isRemoved) {
      setShowRemoveModal(false);
      setSelectedTeam('');
    }
  }, [isRemoved]);

  const [setChampionshipEntityWeight, { loading }] = useMutation<
    Mutation,
    MutationChampionshipEntityUpdateWeightArgs
  >(UpdateChampionshipEntityWeight);

  const tableColumns = [
    {
      title: '',
      dataIndex: 'championshipTeam',
      render: (championshipTeam: ChampionshipTeam) => (
        <ColorsWrapper>
          {championshipTeam.team.colors.map((color) => (
            <Color key={color} style={{ backgroundColor: color as string }} />
          ))}
        </ColorsWrapper>
      ),
      width: '3%',
    },
    {
      title: t(`${I18N_PREFIX}.team`),
      dataIndex: 'championshipTeam',
      render: (championshipTeam: ChampionshipTeam) => (
        <TeamWrapper>{championshipTeam.team.name}</TeamWrapper>
      ),
    },
    {
      title: 'Pontos',
      dataIndex: 'groupScore',
      render: (groupScore: number) => groupScore,
    },
    {
      title: 'Desempate',
      dataIndex: 'weight',
      render: (weight: number, team: ChampionshipEntity) => (
        <StyledInput
          disabled={loading}
          defaultValue={weight}
          onPressEnter={(e: any) =>
            setChampionshipEntityWeight({
              variables: {
                championshipEntityWeight: [
                  {
                    uuid: team.uuid || '',
                    weight: parseFloat(e.target.value),
                  },
                ],
              },
            })
          }
        />
      ),
    },
    {
      dataIndex: 'uuid',
      render: (uuid: string, team: ChampionshipEntity) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key={`competitions-group-teams-edit-${team.championshipTeam.team.uuid}`}>
                <Link
                  to={`${ROUTES.SportsChampionship}/${championshipUrl}/team/${uuid}/${team.championshipTeam.team.uuid}`}
                >
                  <IconFeed width="12px" height="12px" fill="black" /> Editar Jogadores
                </Link>
              </Menu.Item>

              <Menu.Item key={`competitions-group-teams-delete-${team.championshipTeam.team.uuid}`}>
                {uuid && (
                  <div
                    className="mr-2"
                    onClick={() => {
                      setShowRemoveModal(true);
                      setSelectedTeam(uuid);
                    }}
                  >
                    <IconDelete width="14" height="14" className={'anticon'} />
                    &nbsp;
                    {t(`${I18N_PREFIX}.reset`)}
                  </div>
                )}
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            icon={<Icon3Dots width="15" height="15" />}
            shape="circle"
          ></Button>
        </Dropdown>
      ),
      width: '40px',
    },
  ];

  const list = (teams as ChampionshipEntity[]) || [];

  return (
    <Container>
      <StyledButton
        type="primary"
        icon={<IconPlus className={'anticon'} width="15" height="16" />}
        onClick={() => setShowModal(true)}
      >
        {t(`${I18N_PREFIX}.addTeam.title`)}
      </StyledButton>

      <Card>
        <div className="table-responsive">
          <Table columns={tableColumns} dataSource={list} loading={false} rowKey="uuid" />
        </div>
      </Card>

      <SportsChampionshipGroupTeamsModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        isLoading={false}
        group={group}
        refetch={refetch}
      />
      {showRemoveModal && (
        <RemoveModal
          title={t(`${I18N_PREFIX}.removeTeam.title`)}
          message={t(`${I18N_PREFIX}.removeTeam.message`)}
          isLoading={isRemoving}
          showModal
          onClose={() => setShowRemoveModal(false)}
          onRemoveConfirm={() => {
            deleteTeam({
              variables: {
                uuids: [selectedTeam],
              },
            });
          }}
        />
      )}
    </Container>
  );
};
