import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { transformFormValues, transformInitialValues } from './utils';

import { PageHeader } from 'components/pageHeader';
import { RemoveModal } from 'components/removeModal';
import { SubmitButton } from 'components/submitButton';
import { PagesCreateEditForm } from 'components/websitePages/PagesCreateEditForm';

import { IconDelete } from 'assets/icons/iconDelete';

import {
  Mutation,
  MutationCreatePageArgs,
  MutationDeletePageArgs,
  MutationUpdatePageArgs,
  Query,
  QueryPageArgs,
} from 'queries/types';
import {
  CreateWebsitePage,
  DeleteWebsitePage,
  GetWebsitePage,
  UpdateWebsitePage,
} from 'queries/websitePages';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const I18N_PREFIX = 'website.pages.form';

export const PagesCreateEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  const { uuid } = useParams<{ uuid: string }>();

  const [getWebsitePage, { data: page }] = useLazyQuery<Query, QueryPageArgs>(GetWebsitePage, {
    variables: {
      uuid,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (uuid) getWebsitePage();
  }, [uuid]);

  const [form] = Form.useForm();

  const [createPage, { loading: isCreating, data: isCreated }] = useMutation<
    Mutation,
    MutationCreatePageArgs
  >(CreateWebsitePage);
  const [updatePage, { loading: isUpdating }] = useMutation<Mutation, MutationUpdatePageArgs>(
    UpdateWebsitePage
  );
  const [deletePage, { loading: isRemoving, data: isRemoved }] = useMutation<
    Mutation,
    MutationDeletePageArgs
  >(DeleteWebsitePage);

  useEffect(() => {
    if (isCreated || isRemoved) history.push(`${ROUTES.WebsitePages}`);
  }, [isCreated, isRemoved]);

  const onFinish = async (values: any) => {
    if (uuid) {
      updatePage({
        variables: {
          uuid,
          input: transformFormValues(values),
        },
      });
    } else {
      createPage({
        variables: {
          input: transformFormValues(values),
        },
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (uuid && !page?.page) return null;

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="website-form"
        className="website-create-edit-form"
        initialValues={page?.page ? (transformInitialValues(page?.page) as Store) : undefined}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <StyledPageHeader title={t(`${I18N_PREFIX}.header.${uuid ? 'edit' : 'create'}`)}>
          <ButtonsWrapper>
            {uuid && (
              <Button
                className="mr-2"
                icon={<IconDelete width="14" height="14" className={'anticon'} />}
                htmlType="reset"
                onClick={() => setShowRemoveModal(true)}
              >
                {t(`${I18N_PREFIX}.reset`)}
              </Button>
            )}
            <SubmitButton isLoading={isCreating || isUpdating} label={t(`${I18N_PREFIX}.submit`)} />
          </ButtonsWrapper>
        </StyledPageHeader>
        <PagesCreateEditForm form={form} props />
      </Form>
      {showRemoveModal && (
        <RemoveModal
          title={t('modals.pageRemoveModal.title')}
          message={t('modals.pageRemoveModal.message')}
          isLoading={isRemoving}
          showModal
          onClose={() => setShowRemoveModal(false)}
          onRemoveConfirm={() =>
            deletePage({
              variables: {
                uuid,
              },
            })
          }
        />
      )}
    </>
  );
};
