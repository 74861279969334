import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dropdown, Menu, Spin } from 'antd';
import { ColorMain } from 'config/main';
import { useEffect, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/config';
import styled from 'styled-components';

import { RemoveModal } from 'components/removeModal';

import { Icon3Dots } from 'assets/icons/icon3Dots';
import { IconDelete } from 'assets/icons/iconDelete';
import { IconPlus } from 'assets/icons/iconPlus';
import { IconSettings } from 'assets/icons/iconSettings';

import {
  Mutation,
  MutationDeletePageCategoryArgs,
  PageCategory,
  Query,
  QueryPageCategoriesArgs,
} from 'queries/types';
import { DeleteWebsiteCategory, GetWebsiteCategories } from 'queries/websiteCategories';

const I18N_PREFIX: string = 'website.categories';

const Container = styled.div`
  display: flex;
`;

const Column = styled.div`
  border: 1px solid #eef2f8;
  background: white;
  width: calc(100% / 3);
`;

const StyledH4 = styled.h4`
  padding: 20px 23px;
  margin: 0;
  border-bottom: 1px solid #eef2f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledUl = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  max-height: calc(100vh - 320px);
  overflow-y: auto;
`;

const StyledLi = styled.li`
  padding: 23px;
  transition: background-color 0.25s ease-in-out;
  flex-grow: 1;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eef2f8;

  &:hover,
  &:focus,
  &.active {
    cursor: pointer;
    background-color: #f7f7f8;

    h6 {
      color: #9845fd;
    }
  }
`;

const Name = styled.h6`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 3px;
  transition: color 0.25s ease-in-out;
`;

const Page = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #9fa8b7;
`;

export const WebsiteCategoriesList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { firstLevelUuid, secondLevelUuid } = useParams<{
    firstLevelUuid: string;
    secondLevelUuid: string;
  }>();

  const [categoryIdToRemove, setCategoryIdToRemove] = useState<string>('');

  const [getCategories, { data, loading }] = useLazyQuery<Query, QueryPageCategoriesArgs>(
    GetWebsiteCategories,
    {
      variables: {
        filter: {
          parentUuid: null,
        },
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getCategories();
  }, []);

  const [deleteCategory, { loading: isRemoving, data: isRemoved }] = useMutation<
    Mutation,
    MutationDeletePageCategoryArgs
  >(DeleteWebsiteCategory, {
    refetchQueries: [
      {
        query: GetWebsiteCategories,
        variables: {
          filter: {
            parentUuid: null,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (isRemoved) {
      setCategoryIdToRemove('');
    }
  }, [isRemoved]);

  const generateCategoryUrl = (level: number, categoryUuid: string): string => {
    switch (level) {
      case 2:
        return `${ROUTES.WebsiteCategories}/${firstLevelUuid}/${secondLevelUuid}/${categoryUuid}`;
      case 1:
        return `${ROUTES.WebsiteCategories}/${firstLevelUuid}/${categoryUuid}`;
      default:
        return `${ROUTES.WebsiteCategories}/${categoryUuid}`;
    }
  };

  const isCategoryActive = (level: number, categoryUuid: string): boolean => {
    if (level === 0) {
      return firstLevelUuid === categoryUuid;
    }

    if (level === 1) {
      return secondLevelUuid === categoryUuid;
    }

    return false;
  };

  const firstLevel = (data?.pageCategories.edges as any) || [];
  const secondLevel =
    firstLevel.find((category: PageCategory) => category!.uuid === firstLevelUuid)
      ?.pageCategories || [];
  const thirdLevel =
    secondLevel.find((category: PageCategory) => category!.uuid === secondLevelUuid)
      ?.pageCategories || [];
  const levels = [firstLevel, secondLevel, thirdLevel];

  return (
    <Container>
      {loading && <Loading />}
      {!loading &&
        levels.map((level, key) => (
          <Fragment key={`${I18N_PREFIX}.levels.level${key}`}>
            {(key === 0 || (key === 1 && firstLevelUuid) || (key === 2 && secondLevelUuid)) && (
              <Column>
                <StyledH4>
                  {t(`${I18N_PREFIX}.levels.level${key}`)}
                  <Button
                    onClick={() => history.push(`${generateCategoryUrl(key, '')}create`)}
                    icon={<IconPlus width="15" height="15" fill={ColorMain} />}
                    shape="circle"
                    type="text"
                  />
                </StyledH4>
                {level.length > 0 && (
                  <StyledUl>
                    {level.map((category: PageCategory) => (
                      <Wrapper
                        key={category.uuid}
                        className={isCategoryActive(key, category.uuid) ? 'active' : ''}
                      >
                        <StyledLi
                          onClick={
                            category.pageCategories
                              ? () => history.push(generateCategoryUrl(key, category.uuid))
                              : undefined
                          }
                        >
                          {category.uuid && (
                            <>
                              <Name>{category.title.pt}</Name>
                              <Page>{category.page?.title.pt}</Page>
                            </>
                          )}
                        </StyledLi>
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key={`edit-website-category-${category.uuid}`}>
                                <Link
                                  to={`${ROUTES.WebsiteCategories}${ROUTES.Edit}/${category.uuid}`}
                                >
                                  <IconSettings width="12px" height="12px" fill="black" /> Editar
                                </Link>
                              </Menu.Item>
                              <Menu.Item
                                key={`edit-website-category-${category.uuid}`}
                                onClick={() => setCategoryIdToRemove(category.uuid)}
                              >
                                <div>
                                  <IconDelete width="12px" height="12px" fill="black" /> Apagar
                                </div>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={['click']}
                        >
                          <Button
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                            icon={<Icon3Dots width="15px" height="15px" />}
                            shape="circle"
                            type="text"
                          ></Button>
                        </Dropdown>
                      </Wrapper>
                    ))}
                  </StyledUl>
                )}
                {!!categoryIdToRemove && (
                  <RemoveModal
                    title={t('modals.categoriesRemoveModal.title')}
                    message={t('modals.categoriesRemoveModal.message')}
                    isLoading={isRemoving}
                    showModal
                    onClose={() => setCategoryIdToRemove('')}
                    onRemoveConfirm={() =>
                      deleteCategory({
                        variables: {
                          uuid: categoryIdToRemove,
                        },
                      }).catch((error) => {
                        console.log('Error:', error);
                        setCategoryIdToRemove('');
                      })
                    }
                  />
                )}
              </Column>
            )}
          </Fragment>
        ))}
    </Container>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: center;
`;

const Loading = () => {
  return (
    <LoadingWrapper>
      <Spin />
    </LoadingWrapper>
  );
};
