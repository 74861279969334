import { useMutation } from '@apollo/client';
import { Button } from 'antd';
import { ColorError } from 'config/main';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { RemoveModal } from 'components/removeModal';

import { IconDelete } from 'assets/icons/iconDelete';

import { DeleteChampionshipMatchEvent } from 'queries/sportsChampionshipMatch';
import {
  ChampionshipMatchEvent,
  Mutation,
  MutationChampionshipMatchEventDeleteArgs,
} from 'queries/types';

const StyledSingleEvent = styled.li`
  width: 50%;
  text-align: left;
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-right: calc(33px / 2);
  margin-bottom: 25px;
  position: relative;
  padding: 4px 0;
  background: white;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  & > div {
    margin-right: 16px;
  }

  &.right {
    text-align: right;
    align-self: flex-start;
    flex-flow: row-reverse;
    margin-right: 0;
    margin-left: calc(33px / 2);

    & > div {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  &.center {
    text-align: center;
    align-self: center;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;

    & > div {
      margin-right: 0;
      margin-left: 0;
    }

    p {
      color: #9845fd;
    }
  }

  &.main {
    font-size: 16px;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const Logo = styled.div`
  width: 33px;
  height: 33px;
  border: 1px solid #9fa8b7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: white;

  &.blank {
    background: #ecedf2;
    border-color: #ecedf2;
  }
`;

const TitleSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-weight: bold;
  color: #40424e;
  margin-bottom: 0;
  line-height: 18px;
`;

const Subtitle = styled.span`
  color: #7d7f8a;
`;

const Time = styled.div`
  color: #9845fd;
  font-size: 17px;
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translate(-100%, -50%);

  &.ant-btn.ant-btn-loading {
    position: absolute;
  }

  &.right {
    left: auto;
    right: -25px;
    transform: translate(100%, -50%);
  }

  &.main {
    position: static;
    transform: none;
    margin-top: 10px;
  }
`;

interface Props {
  event: ChampionshipMatchEvent;
  main?: boolean;
  canBeDeleted: boolean;
  refetchEvents: any;
}

export const SingleEvent = ({ event, canBeDeleted, refetchEvents, main = false }: Props) => {
  const {
    title,
    subtitle,
    championshipEvent,
    time,
    championshipMatchEntityPlayer,
    auxChampionshipMatchEntityPlayer,
    championshipMatchEntity,
    valueClockAggregated,
  } = event;
  const { t } = useTranslation();
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [deleteMatchEvent, { loading: isDeleting, data: isDeleted }] = useMutation<
    Mutation,
    MutationChampionshipMatchEventDeleteArgs
  >(DeleteChampionshipMatchEvent, refetchEvents);

  useEffect(() => {
    if (isDeleted) setShowRemoveModal(false);
  }, [isDeleted]);

  const getSingleEventClasses = (): string => {
    let singleEventClasses = !championshipMatchEntity ? 'center' : '';

    singleEventClasses =
      singleEventClasses !== 'center' && championshipMatchEntity?.position === 0
        ? 'right'
        : singleEventClasses;

    singleEventClasses += main || !event.championshipMatchEntity ? ' main' : '';

    return singleEventClasses;
  };

  const player = championshipMatchEntityPlayer?.championshipEntityPlayer.player;
  const auxPlayer = auxChampionshipMatchEntityPlayer?.championshipEntityPlayer.player;

  const displayTitle =
    (auxPlayer && `${auxPlayer?.firstName} ${auxPlayer?.lastName}`) ||
    title ||
    championshipEvent?.name.pt ||
    '';
  const displaySubtitle = (player && `${player?.firstName} ${player?.lastName}`) || subtitle || '';

  const logo = championshipEvent?.logo?.url;

  return (
    <StyledSingleEvent className={getSingleEventClasses()}>
      {canBeDeleted ? (
        <DeleteButton
          icon={<IconDelete width="14" height="14" fill={ColorError} />}
          onClick={() => setShowRemoveModal(true)}
          type="default"
          loading={isDeleting}
          shape="circle"
          className={getSingleEventClasses()}
        />
      ) : null}

      {!main ||
        (!event.championshipMatchEntity && (
          <Logo className={!logo ? 'blank' : ''}>
            {logo && <img src={logo} alt={championshipEvent?.name.pt || subtitle} />}
          </Logo>
        ))}
      {valueClockAggregated ? (
        <Time>{valueClockAggregated}</Time>
      ) : (
        time !== undefined && time !== null && <Time>{moment.duration(time).asMinutes()}'</Time>
      )}
      <TitleSubtitleWrapper>
        {displayTitle && <Title>{displayTitle}</Title>}
        {displaySubtitle && <Subtitle>{displaySubtitle}</Subtitle>}
      </TitleSubtitleWrapper>
      {showRemoveModal && (
        <RemoveModal
          title={t('modals.sportsChampionshipMatchEventRemoveModal.title')}
          message={t('modals.sportsChampionshipMatchEventRemoveModal.message')}
          showModal
          isLoading={isDeleting}
          onClose={() => setShowRemoveModal(false)}
          onRemoveConfirm={() => {
            deleteMatchEvent({
              variables: {
                championshipMatchEventUuid: event.uuid,
              },
            });
          }}
        />
      )}
    </StyledSingleEvent>
  );
};
